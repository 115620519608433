// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

/*
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
ActiveStorage.start()

see: https://medium.com/swlh/instant-search-results-with-rails-6-and-stimulus-ffaf333926b5
*/

window.Rails = require("@rails/ujs")
require("@rails/activestorage").start()

import "@hotwired/turbo-rails"
import '../js/bootstrap_js_files.js' 

import "trix";
import "@rails/actiontext"
import "./controllers"
import { linkClickSelector } from '@rails/ujs';

/* TODO Find a better location */
(function () {
  window.addEventListener('DOMContentLoaded', (event) => {
    document.addEventListener('click', function(e) {
      if (!e.srcElement.classList.contains("dismiss-link")) return;
      if (!e.srcElement.parentNode.classList.contains("dismissable")) return;

      e.srcElement.parentNode.classList.add("dismissed");
    });
  });
  
  window.addEventListener('DOMContentLoaded', (event) => {
    document.addEventListener("trix-file-accept", event => {
      event.preventDefault();
    });
  });
  
  window.addEventListener('DOMContentLoaded', (event) => {
    document.addEventListener("turbolinks:click", (event) => {
      const anchorElement = event.target
      const isSamePageAnchor = (
        anchorElement.hash &&
        anchorElement.origin === window.location.origin &&
        anchorElement.pathname === window.location.pathname
      )

      if (!isSamePageAnchor) return

      event.preventDefault()

      Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
        event.data.url,
        Turbolinks.uuid()
      )
    })
  });

})();

Rails.start();

console.log("we are ready 29.19.42");
