// topic_help_controll.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "topicSource", "topicDetails" ]
  
  initialize() {
    this.showCurrentHelp()
  }
  
  showCurrentHelp() {
    const selectedTopicId = this.topicSourceTarget.selectedOptions[0].value
    var oneTopicWasDisplayed = false;
    this.topicDetailsTargets.forEach((element, index) => {
      if (element.getAttribute("data-topic-help-topic-id") == selectedTopicId) {
        element.hidden = false
        oneTopicWasDisplayed = true
      } else {
        element.hidden = true        
      }
    })
    // document.getElementById('topic-help').style.display = (oneTopicWasDisplayed ? "" : "none");
    this.element.querySelector('.topic-help').hidden = !oneTopicWasDisplayed
    console.log("one topic was displayed:", oneTopicWasDisplayed)
  }
}
