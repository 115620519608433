// star_rating_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  //static targets = [ "rating" ]
  
  submitRating() {
    console.log("submitting results")
    Rails.fire(this.element , 'submit')
  }
  
  submitSuccess(event) {
    const [data, status, xhr] = event.detail
    console.log(xhr.response)    
  }
  
  submitError() {
    console.log("ERROR")
  }
  
  connect() {
    this.element.addEventListener("ajax:success", (event) => this.submitSuccess)
    this.element.addEventListener("ajax:error", () => this.submitError)
    
    this.element.classList.add("star-rating-loaded")
  }
}
