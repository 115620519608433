
// hello_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.logId = Math.random();
    this.log("initialized")
  }
  
  connect() {
    this.log("connect")
  }
  
  disconnect() {
    this.log("disconnect")
  }

  greet() {
    this.log(`Hello, ${this.name}!`)
  }

  get name() {
    return this.targets.find("name").value
  }
  
  log(text) {
    console.log(`instance${this.logId}`, text)
  }
}
